import React from "react"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import TitleBannerEnquiry from "../components/titleBanner/TitleBannerEnquiry"
import CardsList from "../components/common/cardsList/CardsList"

const CoursesBim = ({ data }) => {
  const { bimCourses } = data
  return (
    <div>
      <SEO
        title="BIM Courses"
        // description=""
      />

      <TitleBannerEnquiry
        title="BIM Courses"
        // description=""
        withOnlyDescription
      />

      <div className="container">
        <CardsList
          courses={bimCourses.nodes}
          withoutFilter
        />
      </div>
    </div>
  )
}

export const awsQuery = graphql`
  query allBimQuery {
    bimCourses: allContentfulTraining(
      filter: { category: { eq: "bim" } }
    ) {
      nodes {
        id
        title
        category
        courseReference
        trainingMode
        updatedAt
        slug
        skillsFramework
        requirements
        lessons
        product {
          id
          name
          netFee
          price
        }
        duration
        coverPhoto {
          gatsbyImageData
        }
        courseRuns
      }
    }
  }
`

export default CoursesBim
